import React from 'react'
import { Flex, Box } from 'reflexbox'
import scrollIntoView from 'scroll-into-view'

import Container from 'src/components/UI/Grid/Container'
import Logotype from 'src/components/UI/Elements/Logotype/Logotype'

import styles from './Header.module.scss'

const navigation = [
  { id: 'hero', name: 'Hero' },
  { id: 'about', name: 'Om Swapp' },
  { id: 'signup', name: 'Anmäl dig' },
  { id: 'contact', name: 'Kontakta oss' },
]

const Header = () => {
  const scrollToSection = (id: string) => {
    if (id) {
      const el = document.getElementById(id)
      if (el) {
        scrollIntoView(el, {
          time: 1000,
          align: {
            top: 0,
            left: 0,
          },
        })
      }
    }
  }
  return (
    <Flex className={styles.wrapper} as="header">
      <Container
        flexDirection="row"
        paddingBottom={[0, null, null, 8]}
        alignItems={['center', null, null, 'flex-end']}
        justifyContent={['center', null, null, 'space-between']}
      >
        <Box>
          <button
            className={styles.logotype}
            onClick={() => scrollToSection('hero')}
          >
            <Logotype width={150} />
          </button>
        </Box>
        {navigation?.length > 0 && (
          <Box className="hideMobile" as="nav">
            {navigation.map((item, index) => {
              const { id, name } = item
              return (
                <Box
                  key={'navigation' + index}
                  as="button"
                  className={styles.link}
                  onClick={() => scrollToSection(id)}
                  marginLeft={[8]}
                  data-ref={id}
                >
                  {name}
                </Box>
              )
            })}
          </Box>
        )}
      </Container>
    </Flex>
  )
}

export default Header
