import React from 'react'
import { Flex, Box } from 'reflexbox'

import LinkComponent from 'src/components/LinkComponent/LinkComponent'
import Logotype from 'src/components/UI/Elements/Logotype/Logotype'
import { FacebookIcon, YouTubeIcon } from 'src/components/UI/Elements/Icon/Icon'
import Container from 'src/components/UI/Grid/Container'

import styles from './Footer.module.scss'

const Footer = () => {
  return (
    <Container as="section" paddingY={[40]} alignItems="center">
      <div id="contact" className={styles.anchor} data-anchor />
      <Logotype width={250} />
      <Box marginTop={[6]} marginBottom={[10]}>
        📫{' '}
        <LinkComponent
          className={styles.link}
          to="mailto:hej@getswapp.io"
          title="Facebook"
        >
          hej@getswapp.io
        </LinkComponent>
      </Box>
      <Flex>
        <Box marginX={[2]}>
          <LinkComponent
            className={styles.icon}
            to="https://www.facebook.com/goswapp"
            title="Facebook"
          >
            <FacebookIcon width={20} />
          </LinkComponent>
        </Box>
        <Box marginX={[2]}>
          <LinkComponent
            className={styles.icon}
            to="https://www.youtube.com/channel/UCPzQx73_sjKturOpkwy1Y2Q?app=desktop"
            title="Youtube"
          >
            <YouTubeIcon width={22} />
          </LinkComponent>
        </Box>
      </Flex>
    </Container>
  )
}

export default Footer
