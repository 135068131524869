import React from 'react'
import { reaction } from 'mobx'
import Helmet from 'react-helmet'

import Header from 'src/components/Header/Header'
import Footer from 'src/components/Footer/Footer'
import SEO from 'src/components/SEO/SEO'
import useStore from 'src/stores/useStore'
import seoImage from 'src/assets/images/share.png'

import { Props } from './types'

// eslint-disable-next-line react/display-name
export default ({ children }: Props) => {
  const { ui } = useStore()

  const onResize = () => {
    ui?.setWindowSize({
      height: window.innerHeight,
      width: window.innerWidth,
    })
  }

  React.useEffect(() => {
    if (document) {
      const sections = document.querySelectorAll('section')
      const config = {
        rootMargin: '0px 0px -108px',
        threshold: 0,
      }

      const intersectionHandler = (entry) => {
        const target = entry.target
        let id = target.id

        if (!id) {
          id = target.querySelector(`[data-anchor]`)
            ? target.querySelector(`[data-anchor]`).getAttribute('id')
            : null
        }
        if (id) {
          const currentlyActive = document.querySelector('nav button.active')
          const shouldBeActive = document.querySelector(
            `nav button[data-ref="${id}"]`
          )

          if (entry.isIntersecting) {
            console.log('intersectionHandler -> target', target)
            console.log('INTERSECTING')

            if (currentlyActive) {
              currentlyActive.classList.remove('active')
            }
            if (shouldBeActive) {
              shouldBeActive.classList.add('active')
            }
          }
        }
      }

      const observer = new IntersectionObserver(function (entries, self) {
        entries.forEach((entry) => {
          if (entry) {
            intersectionHandler(entry)
          }
        })
      }, config)

      // sections.forEach((section) => {
      //   observer.observe(section)
      // })
    }
    const disposer = reaction(
      () => ui.activeMenu,
      () => {
        if (ui.activeMenu) {
          document.body.classList.add('prevent-overflow')
        } else {
          document.body.classList.remove('prevent-overflow')
        }
      }
    )
    ui?.setWindowSize({
      height: window.innerHeight,
      width: window.innerWidth,
    })
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
      disposer()
    }
  }, [])
  const seoTitle = 'Swapp'
  const seoDescription =
    'Swapp är en tjänst där du kan byta vad som helst, när som helst med vem som helst.'

  return (
    <React.Fragment>
      <Helmet titleTemplate={`${seoTitle} | %s`} defaultTitle={seoTitle} />
      <SEO metaDescription={seoDescription} image={seoImage} />
      <Header />
      {children}
      <Footer />
    </React.Fragment>
  )
}
