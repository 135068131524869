import React from 'react'

import { Props } from './types'

const iconColor = '#000000'

const getHeight = (width: number, ratio: number) => {
  return Math.round(width * ratio)
}

export const FacebookIcon = ({
  color = iconColor,
  className,
  width,
  height,
}: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width={width}
    height={height || getHeight(width, 1 / 1)}
    viewBox="0 0 24 24"
  >
    <path
      fill={color}
      d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"
    />
  </svg>
)
export const YouTubeIcon = ({
  color = iconColor,
  className,
  width,
  height,
}: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width={width}
    height={height || getHeight(width, 1 / 1)}
    viewBox="0 0 24 24"
  >
    <path
      fill={color}
      d="M23.499 6.203a3.008 3.008 0 00-2.089-2.089c-1.87-.501-9.4-.501-9.4-.501s-7.509-.01-9.399.501a3.008 3.008 0 00-2.088 2.09A31.258 31.26 0 000 12.01a31.258 31.26 0 00.523 5.785 3.008 3.008 0 002.088 2.089c1.869.502 9.4.502 9.4.502s7.508 0 9.399-.502a3.008 3.008 0 002.089-2.09 31.258 31.26 0 00.5-5.784 31.258 31.26 0 00-.5-5.808zm-13.891 9.4V8.407l6.266 3.604z"
    />
  </svg>
)
export const PlayIcon = ({
  color = iconColor,
  className,
  width,
  height,
}: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width={width}
    height={height || getHeight(width, 1 / 1)}
    viewBox="0 0 24 24"
    fill={color}
  >
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M8 5v14l11-7z" />
  </svg>
)
