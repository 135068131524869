import * as React from 'react'
import { Link } from 'gatsby'

import { Props } from './types'

const LinkComponent = ({
  to,
  children,
  onClick,
  className,
  title,
  onMouseEnter,
  onMouseLeave,
  ariaLabel,
}: Props): JSX.Element => {
  if (!to) {
    return <React.Fragment>{children}</React.Fragment>
  }
  const isExternal =
    to.substr(0, 4) === 'http' ||
    to.substr(0, 7) === 'mailto:' ||
    to.substr(0, 9) === 'data:text'

  const isDownload = to.substr(0, 9) === 'data:text'

  return isExternal ? (
    <a
      aria-label={ariaLabel}
      className={className}
      href={to}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      target="_blank"
      rel="noopener noreferrer"
      title={title}
      download={isDownload}
    >
      {children}
    </a>
  ) : (
    <Link
      aria-label={ariaLabel}
      className={className}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      title={title}
      to={to}
    >
      {children}
    </Link>
  )
}

export default LinkComponent
