import React from 'react'

import { Props } from './types'

const getHeight = (width: number, ratio: number) => {
  return Math.round(width * ratio)
}

const Logotype = ({ className, width, height }: Props) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width={width}
    height={height || getHeight(width, 82.356 / 250)}
    className={className}
    viewBox="0 0 250 82.356"
    enableBackground="new 0 0 250 82.356"
  >
    <g>
      <path
        fill="#FCF266"
        d="M33.94,19.045c-0.349,0-0.69-0.117-0.965-0.338l-6.029-4.804c-0.669-0.535-0.779-1.508-0.246-2.178
       c0.534-0.668,1.508-0.78,2.177-0.245l4.804,3.827l3.776-4.845c0.525-0.676,1.5-0.797,2.174-0.27
       c0.675,0.526,0.796,1.499,0.27,2.175l-4.74,6.081c-0.255,0.326-0.628,0.538-1.04,0.587C34.062,19.042,34.001,19.045,33.94,19.045"
      />
    </g>
    <g>
      <path
        fill="#5CE6BF"
        d="M1.933,47.633c-0.415,0-0.833-0.133-1.187-0.409c-0.844-0.657-0.994-1.872-0.337-2.713l4.739-6.083
       c0.317-0.408,0.785-0.672,1.299-0.732c0.515-0.058,1.029,0.087,1.434,0.408l6.028,4.806c0.835,0.666,0.973,1.883,0.307,2.718
       c-0.666,0.835-1.883,0.973-2.719,0.307l-4.499-3.586l-3.537,4.54C3.079,47.376,2.51,47.633,1.933,47.633"
      />
    </g>
    <g>
      <path
        fill="#5CE6BF"
        d="M42.103,39.933c-0.416,0-0.834-0.132-1.188-0.408c-0.842-0.657-0.994-1.873-0.337-2.715l4.739-6.082
       c0.318-0.409,0.786-0.673,1.299-0.732c0.515-0.063,1.029,0.086,1.434,0.407l6.029,4.806c0.834,0.665,0.972,1.884,0.306,2.719
       c-0.666,0.836-1.883,0.975-2.718,0.307l-4.5-3.586l-3.537,4.54C43.249,39.677,42.679,39.933,42.103,39.933"
      />
    </g>
    <g>
      <path
        fill="#FCF266"
        d="M115.055,39.933c-0.576,0-1.146-0.256-1.528-0.745l-3.538-4.54l-4.499,3.586
       c-0.835,0.668-2.053,0.529-2.719-0.307c-0.666-0.834-0.528-2.054,0.308-2.719l6.028-4.806c0.405-0.321,0.92-0.47,1.435-0.407
       c0.513,0.06,0.981,0.323,1.298,0.732l4.74,6.082c0.656,0.842,0.505,2.058-0.337,2.715C115.89,39.801,115.47,39.933,115.055,39.933"
      />
    </g>
    <g>
      <path
        fill="#5CE6BF"
        d="M65.797,55.658c-11.221,0-20.349-9.128-20.349-20.348c0-1.068,0.866-1.935,1.935-1.935
       c1.068,0,1.935,0.867,1.935,1.935c0,9.087,7.393,16.478,16.479,16.478c10.72,0,17.833-14.031,17.833-23.312
       c0-2.477-0.486-4.505-1.406-5.867c-0.89-1.316-2.164-1.957-3.894-1.957c-1.068,0-1.935-0.866-1.935-1.934
       c0-1.068,0.867-1.935,1.935-1.935c3.026,0,5.48,1.267,7.099,3.66c1.355,2.003,2.07,4.78,2.07,8.033
       C87.499,39.559,79.045,55.658,65.797,55.658"
      />
    </g>
    <g>
      <path
        fill="#FCF266"
        d="M91.277,55.658c-13.405,0-21.96-16.287-21.96-27.499c0-3.173,0.704-5.88,2.035-7.825
       c1.588-2.323,4.002-3.55,6.978-3.55c1.069,0,1.935,0.867,1.935,1.935c0,1.068-0.866,1.934-1.935,1.934
       c-1.688,0-2.926,0.612-3.784,1.866c-0.889,1.3-1.359,3.25-1.359,5.641c0,9.515,7.597,23.629,18.091,23.629
       c9.086,0,16.479-7.391,16.479-16.478c0-1.068,0.866-1.935,1.935-1.935c1.069,0,1.935,0.867,1.935,1.935
       C111.625,46.53,102.497,55.658,91.277,55.658"
      />
    </g>
    <g>
      <path
        fill="#FCF266"
        d="M219.624,82.356c-0.517,0-1.012-0.207-1.375-0.574l-5.425-5.479c-0.752-0.76-0.745-1.984,0.014-2.736
       c0.76-0.75,1.985-0.745,2.735,0.015l4.049,4.089l4.043-4.094c0.749-0.761,1.975-0.767,2.735-0.018
       c0.761,0.751,0.769,1.976,0.018,2.735l-5.418,5.486C220.636,82.149,220.141,82.356,219.624,82.356L219.624,82.356z"
      />
    </g>
    <g>
      <path
        fill="#5CE6BF"
        d="M212.299,56.24c-0.491,0-0.982-0.187-1.359-0.557l-5.486-5.42c-0.367-0.363-0.573-0.858-0.574-1.374
       c0-0.518,0.206-1.011,0.573-1.375l5.479-5.426c0.759-0.751,1.984-0.746,2.736,0.013c0.752,0.761,0.745,1.985-0.015,2.739
       l-4.088,4.046l4.093,4.042c0.76,0.751,0.769,1.976,0.017,2.736C213.298,56.048,212.798,56.24,212.299,56.24"
      />
    </g>
    <g>
      <path
        fill="#5CE6BF"
        d="M233.846,50.818h-23.248c-1.069,0-1.935-0.865-1.935-1.935s0.866-1.935,1.935-1.935h23.248
       c6.918,0,12.285-7.53,12.285-14.011c0-3.28-1.278-6.367-3.6-8.687c-0.755-0.756-0.755-1.981,0.001-2.736
       c0.756-0.757,1.98-0.757,2.736,0C248.32,24.566,250,28.624,250,32.938C250,41.379,243.092,50.818,233.846,50.818"
      />
    </g>
    <g>
      <path
        fill="#FCF266"
        d="M219.62,79.612L219.62,79.612c-1.068,0-1.935-0.865-1.935-1.935l0.007-44.74
       c0-8.907,7.246-16.154,16.154-16.154c4.314,0,8.371,1.68,11.423,4.731c0.755,0.755,0.755,1.98,0,2.736
       c-0.756,0.753-1.98,0.753-2.736,0c-2.32-2.32-5.405-3.599-8.687-3.599c-6.774,0-12.286,5.512-12.286,12.286l-0.005,44.74
       C221.555,78.747,220.689,79.612,219.62,79.612"
      />
    </g>
    <g>
      <path
        fill="#FCF266"
        d="M190.915,55.131c-0.346,0-0.695-0.092-1.012-0.286l-6.566-4.04c-0.44-0.272-0.754-0.708-0.87-1.209
       c-0.117-0.503-0.028-1.032,0.248-1.47l4.113-6.522c0.569-0.903,1.766-1.175,2.668-0.603c0.903,0.569,1.173,1.765,0.604,2.668
       l-3.069,4.867l4.9,3.013c0.909,0.561,1.194,1.752,0.635,2.663C192.2,54.805,191.564,55.131,190.915,55.131"
      />
    </g>
    <g>
      <path
        fill="#5CE6BF"
        d="M173.895,82.356c-0.517,0-1.011-0.207-1.375-0.574l-5.426-5.479c-0.752-0.76-0.745-1.984,0.014-2.736
       c0.761-0.75,1.985-0.745,2.736,0.015l4.048,4.089l4.042-4.094c0.75-0.761,1.976-0.767,2.736-0.018
       c0.76,0.751,0.768,1.976,0.018,2.735l-5.418,5.486C174.906,82.149,174.413,82.356,173.895,82.356L173.895,82.356z"
      />
    </g>
    <g>
      <path
        fill="#5CE6BF"
        d="M173.891,79.612c-0.938,0-1.935-0.71-1.935-2.029l0.005-32.99c0-1.07,0.866-1.935,1.934-1.935h0
       c1.068,0,1.935,0.867,1.935,1.935c0,0-0.005,32.831-0.005,33.082c0,0.514-0.203,1.007-0.566,1.369
       C174.897,79.408,174.405,79.612,173.891,79.612"
      />
    </g>
    <g>
      <path
        fill="#FCF266"
        d="M188.117,50.07c-1.069,0-1.935-0.865-1.935-1.934c0-1.068,0.866-1.935,1.935-1.935
       c6.544,0,12.286-6.199,12.286-13.264c0-6.773-5.511-12.286-12.286-12.286c-6.774,0-12.285,5.513-12.285,12.286l-0.001,11.655
       c0,1.068-0.868,1.935-1.936,1.935s-1.934-0.867-1.934-1.935l0.001-11.655c0-8.907,7.246-16.154,16.154-16.154
       c8.908,0,16.154,7.248,16.154,16.154C204.271,42.064,196.723,50.07,188.117,50.07"
      />
    </g>
    <g>
      <path
        fill="#5CE6BF"
        d="M123.443,23.311c-0.382,0-0.75-0.141-1.036-0.398c-0.308-0.276-0.493-0.664-0.512-1.077l-0.367-7.701
       c-0.041-0.854,0.619-1.581,1.474-1.622c0.863-0.036,1.58,0.619,1.621,1.474l0.293,6.138l6.133-0.36
       c0.856-0.061,1.588,0.6,1.638,1.455c0.05,0.856-0.602,1.587-1.456,1.638l-7.697,0.451
       C123.504,23.311,123.473,23.311,123.443,23.311"
      />
    </g>
    <g>
      <path
        fill="#FCF266"
        d="M159.146,59.479c-0.325,0-0.648-0.081-0.937-0.242l-6.746-3.735c-0.934-0.517-1.272-1.696-0.754-2.63
       c0.518-0.934,1.694-1.274,2.63-0.754l5.034,2.787l2.731-5.063c0.507-0.939,1.68-1.293,2.621-0.783
       c0.94,0.506,1.292,1.68,0.784,2.62l-3.66,6.786c-0.246,0.455-0.664,0.792-1.159,0.938
       C159.511,59.453,159.328,59.479,159.146,59.479"
      />
    </g>
    <g>
      <path
        fill="#5CE6BF"
        d="M148.538,49.095c-0.313,0-0.629-0.075-0.924-0.236c-0.938-0.51-1.285-1.687-0.774-2.624
       c3.693-6.782,4.502-15.814,4.502-18.722c0-5.292-2.607-9.532-6.976-11.342c-5.574-2.309-12.543-0.236-18.188,5.407
       c-0.755,0.755-1.98,0.755-2.735,0c-0.755-0.757-0.755-1.98,0-2.737c6.771-6.771,15.357-9.163,22.403-6.243
       c5.865,2.428,9.365,8.004,9.365,14.915c0,0.112-0.057,11.544-4.973,20.572C149.888,48.729,149.224,49.095,148.538,49.095"
      />
    </g>
    <g>
      <path
        fill="#FCF266"
        d="M135.198,57.973c-8.163,0-14.803-6.641-14.803-14.804s6.64-14.803,14.803-14.803
       c17.991,0,25.306,25.939,25.608,27.043c0.282,1.03-0.324,2.094-1.354,2.377c-1.03,0.28-2.095-0.323-2.377-1.353
       c-0.066-0.242-6.835-24.198-21.877-24.198c-6.029,0-10.934,4.905-10.934,10.933c0,6.029,4.905,10.935,10.934,10.935
       c4.882,0,8.8-2.648,11.642-7.869c0.511-0.938,1.688-1.283,2.625-0.773c0.938,0.511,1.285,1.686,0.773,2.623
       C146.716,54.554,141.515,57.973,135.198,57.973"
      />
    </g>
    <g>
      <path
        fill="#FCF266"
        d="M33.765,17.165c-1.038,0-1.879-0.841-1.879-1.879c0-2.31-0.68-4.539-1.967-6.443
       c-2.151-3.184-5.724-5.084-9.56-5.084c-6.356,0-11.528,5.171-11.528,11.527c0,1.038-0.841,1.879-1.879,1.879
       c-1.038,0-1.879-0.841-1.879-1.879C5.072,6.857,11.93,0,20.358,0c5.085,0,9.823,2.519,12.674,6.738
       c1.708,2.528,2.612,5.484,2.612,8.547C35.644,16.324,34.803,17.165,33.765,17.165z"
      />
    </g>
    <g>
      <path
        fill="#5CE6BF"
        d="M20.358,57.385c-8.429,0-15.286-6.857-15.286-15.286c0-1.038,0.841-1.879,1.879-1.879
       c1.038,0,1.879,0.842,1.879,1.879c0,6.356,5.171,11.527,11.528,11.527c6.356,0,11.527-5.171,11.527-11.527
       c0-8.061-5.893-10.146-12.717-12.561C12.561,27.2,5.072,24.551,5.072,15.286c0-1.038,0.841-1.879,1.879-1.879
       c1.038,0,1.879,0.841,1.879,1.879c0,6.289,4.405,8.167,11.591,10.709c6.782,2.399,15.222,5.386,15.222,16.104
       C35.644,50.527,28.787,57.385,20.358,57.385z"
      />
    </g>
  </svg>
)

export default Logotype
